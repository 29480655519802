import { ApolloClient, from, InMemoryCache } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from "crypto-hash";

let uri = "/graphql";

if (process.env.NODE_ENV === "development") {
    uri = "https://dev.api.kellvin.aptus.be/graphql";
}

const link = from([createPersistedQueryLink({ sha256 }), new BatchHttpLink({ uri })]);

export const client = new ApolloClient({
    link,
    cache: new InMemoryCache({})
});
