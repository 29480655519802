import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React, { FormEventHandler, ReactElement } from "react";

import { useUpdatePasswordAnonymousMutation } from "../graphql/generatedHooks";
import { useStyles } from "./resetPasswordPage.style";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://www.qelviq.com/en/">
                QelviQ
            </Link>{" "}
            {new Date().getFullYear()}
        </Typography>
    );
}

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const ResetPasswordPage: React.FunctionComponent = () => {
    const classes = useStyles();

    const [password, setPassword] = React.useState("");
    const [showSnackBar, setShowSnackBar] = React.useState(false);
    const [alert, setAlert] = React.useState<ReactElement>();
    const [updatePasswordAnonymous] = useUpdatePasswordAnonymousMutation();

    const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");

        if (token && password) {
            try {
                const result = await updatePasswordAnonymous({
                    variables: {
                        data: {
                            password,
                            token
                        }
                    }
                });

                if (result && result.data && result.data.updatePasswordAnonymous) {
                    setAlert(
                        <Alert onClose={handleClose} severity="success">
                            Your password has been reset. You can sign back in.
                        </Alert>
                    );
                } else if (result && result.errors) {
                    setAlert(
                        <Alert onClose={handleClose} severity="error">
                            {result.errors[0] && result.errors[0].message}
                        </Alert>
                    );
                }
            } catch (error) {
                setAlert(
                    <Alert onClose={handleClose} severity="error">
                        Something went wrong. Please try again or request a new password reset e-mail.
                    </Alert>
                );
            }
        } else {
            setAlert(
                <Alert onClose={handleClose} severity="warning">
                    Please enter a valid password
                </Alert>
            );
        }

        setShowSnackBar(true);
    };

    const handleClose = () => {
        setShowSnackBar(false);
    };

    return (
        <Container component="main" maxWidth="xs">
            <Snackbar open={showSnackBar} autoHideDuration={6000} onClose={handleClose}>
                {alert}
            </Snackbar>

            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Reset your password
                </Typography>
                <form className={classes.form} onSubmit={onSubmit} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="New password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(event) => setPassword(event.currentTarget.value)}
                    />
                    <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                        Reset password
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
};
