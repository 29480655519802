/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  JSON: any;
  Time: any;
  Upload: File | FileList | Blob;
};


export type AdviceRequest = {
  __typename?: 'AdviceRequest';
  id: Scalars['String'];
  status: AdviceRequestStatus;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  scan: Scan;
  wine?: Maybe<Wine>;
  owner: User;
  dismissed: Scalars['Boolean'];
};

export type AdviceRequestCreateInput = {
  scan: Scalars['String'];
};

export type AdviceRequestFilter = {
  id?: Maybe<StringFilter>;
  status?: Maybe<AdviceRequestStatusFilter>;
  owner?: Maybe<UserFilter>;
  dismissed?: Maybe<BooleanFilter>;
  AND?: Maybe<Array<AdviceRequestFilter>>;
  OR?: Maybe<Array<AdviceRequestFilter>>;
};

export enum AdviceRequestStatus {
  New = 'new',
  Finished = 'finished',
  Deleted = 'deleted'
}

export type AdviceRequestStatusFilter = {
  equals?: Maybe<AdviceRequestStatus>;
  not?: Maybe<AdviceRequestStatus>;
  in?: Maybe<Array<Maybe<AdviceRequestStatus>>>;
  notIn?: Maybe<Array<Maybe<AdviceRequestStatus>>>;
};

export type AdviceRequestUpdateInput = {
  status?: Maybe<AdviceRequestStatus>;
  comment?: Maybe<Scalars['String']>;
  wine?: Maybe<Scalars['String']>;
  dismissed?: Maybe<Scalars['Boolean']>;
};

export type Aoc = {
  __typename?: 'Aoc';
  id: Scalars['String'];
  name: Scalars['String'];
  region?: Maybe<Region>;
  country: Country;
  temperatures?: Maybe<Array<TypeTemperature>>;
};

export type AocCreateInput = {
  name: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  temperatures?: Maybe<Array<Maybe<TypeTemperatureCreateInput>>>;
};

export type AocFilter = {
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  region?: Maybe<RegionFilter>;
  country?: Maybe<CountryFilter>;
  AND?: Maybe<Array<AocFilter>>;
  OR?: Maybe<Array<AocFilter>>;
};

export type AocUpdateInput = {
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  temperatures?: Maybe<Array<Maybe<TypeTemperatureCreateInput>>>;
};

export type BasicStringArrayFilter = {
  containsSome?: Maybe<Array<Maybe<Scalars['String']>>>;
  containsAll?: Maybe<Array<Maybe<Scalars['String']>>>;
  equals?: Maybe<Array<Maybe<Scalars['String']>>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type BasicStringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type BooleanFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<Scalars['Boolean']>;
};

export type Classification = {
  __typename?: 'Classification';
  id: Scalars['String'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type ClassificationCreateInput = {
  name: Scalars['String'];
  type: Scalars['String'];
};

export type ClassificationFilter = {
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  AND?: Maybe<Array<ClassificationFilter>>;
  OR?: Maybe<Array<ClassificationFilter>>;
};

export type ClassificationUpdateInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['String'];
  name: Scalars['String'];
  temperatures?: Maybe<Array<TypeTemperature>>;
};

export type CountryCreateInput = {
  name: Scalars['String'];
  temperatures?: Maybe<Array<Maybe<TypeTemperatureCreateInput>>>;
};

export type CountryFilter = {
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  AND?: Maybe<Array<CountryFilter>>;
  OR?: Maybe<Array<CountryFilter>>;
};

export type CountryUpdateInput = {
  name?: Maybe<Scalars['String']>;
  temperatures?: Maybe<Array<Maybe<TypeTemperatureCreateInput>>>;
};



export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  not?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type Domain = {
  __typename?: 'Domain';
  id: Scalars['String'];
  name: Scalars['String'];
  aoc?: Maybe<Aoc>;
  region?: Maybe<Region>;
  country: Country;
};

export type DomainCreateInput = {
  name: Scalars['String'];
  aoc?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  country: Scalars['String'];
};

export type DomainFilter = {
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  aoc?: Maybe<AocFilter>;
  region?: Maybe<RegionFilter>;
  country?: Maybe<CountryFilter>;
  AND?: Maybe<Array<DomainFilter>>;
  OR?: Maybe<Array<DomainFilter>>;
};

export type DomainUpdateInput = {
  name?: Maybe<Scalars['String']>;
  aoc?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type Firmware = {
  __typename?: 'Firmware';
  id: Scalars['String'];
  version: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
};

export type FirmwareCreateInput = {
  version: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type FirmwareFilter = {
  id?: Maybe<StringFilter>;
  version?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  AND?: Maybe<Array<FirmwareFilter>>;
  OR?: Maybe<Array<FirmwareFilter>>;
};

export type FirmwareUpdateInput = {
  version?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Grape = {
  __typename?: 'Grape';
  id: Scalars['String'];
  name: Scalars['String'];
  temperatures?: Maybe<Array<TypeTemperature>>;
};

export type GrapeCreateInput = {
  name: Scalars['String'];
  temperatures?: Maybe<Array<Maybe<TypeTemperatureCreateInput>>>;
};

export type GrapeFilter = {
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  AND?: Maybe<Array<GrapeFilter>>;
  OR?: Maybe<Array<GrapeFilter>>;
};

export type GrapeUpdateInput = {
  name?: Maybe<Scalars['String']>;
  temperatures?: Maybe<Array<Maybe<TypeTemperatureCreateInput>>>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  not?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
};


export type LoginResult = {
  __typename?: 'LoginResult';
  token: Scalars['String'];
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  createOneAdviceRequest: AdviceRequest;
  updateOneAdviceRequest: AdviceRequest;
  deleteOneAdviceRequest: Success;
  createOneAoc: Aoc;
  updateOneAoc: Aoc;
  deleteOneAoc: Success;
  createOneClassification: Classification;
  updateOneClassification: Classification;
  deleteOneClassification: Success;
  createOneCountry: Country;
  updateOneCountry: Country;
  deleteOneCountry: Success;
  createOneDomain: Domain;
  updateOneDomain: Domain;
  deleteOneDomain: Success;
  createOneFirmware: Firmware;
  updateOneFirmware: Firmware;
  deleteOneFirmware: Success;
  createOneGrape: Grape;
  updateOneGrape: Grape;
  deleteOneGrape: Success;
  passwordLogin: LoginResult;
  socialLogin: LoginResult;
  passwordRegister: LoginResult;
  updatePassword: Success;
  updatePasswordAnonymous: Success;
  resetPassword: Success;
  createOneRegion: Region;
  updateOneRegion: Region;
  deleteOneRegion: Success;
  createOneScan: Scan;
  updateOneUser: User;
  deleteOneUser: Success;
  createOneWine: Wine;
  updateOneWine: Wine;
  deleteOneWine: Success;
};


export type MutationCreateOneAdviceRequestArgs = {
  data?: Maybe<AdviceRequestCreateInput>;
};


export type MutationUpdateOneAdviceRequestArgs = {
  id: Scalars['String'];
  data?: Maybe<AdviceRequestUpdateInput>;
};


export type MutationDeleteOneAdviceRequestArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneAocArgs = {
  data?: Maybe<AocCreateInput>;
};


export type MutationUpdateOneAocArgs = {
  id: Scalars['String'];
  data?: Maybe<AocUpdateInput>;
};


export type MutationDeleteOneAocArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneClassificationArgs = {
  data?: Maybe<ClassificationCreateInput>;
};


export type MutationUpdateOneClassificationArgs = {
  id: Scalars['String'];
  data?: Maybe<ClassificationUpdateInput>;
};


export type MutationDeleteOneClassificationArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneCountryArgs = {
  data?: Maybe<CountryCreateInput>;
};


export type MutationUpdateOneCountryArgs = {
  id: Scalars['String'];
  data?: Maybe<CountryUpdateInput>;
};


export type MutationDeleteOneCountryArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneDomainArgs = {
  data?: Maybe<DomainCreateInput>;
};


export type MutationUpdateOneDomainArgs = {
  id: Scalars['String'];
  data?: Maybe<DomainUpdateInput>;
};


export type MutationDeleteOneDomainArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneFirmwareArgs = {
  data?: Maybe<FirmwareCreateInput>;
};


export type MutationUpdateOneFirmwareArgs = {
  id: Scalars['String'];
  data?: Maybe<FirmwareUpdateInput>;
};


export type MutationDeleteOneFirmwareArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneGrapeArgs = {
  data?: Maybe<GrapeCreateInput>;
};


export type MutationUpdateOneGrapeArgs = {
  id: Scalars['String'];
  data?: Maybe<GrapeUpdateInput>;
};


export type MutationDeleteOneGrapeArgs = {
  id: Scalars['String'];
};


export type MutationPasswordLoginArgs = {
  data: PasswordLoginInput;
};


export type MutationSocialLoginArgs = {
  data: SocialLoginInput;
};


export type MutationPasswordRegisterArgs = {
  data: PasswordRegisterInput;
};


export type MutationUpdatePasswordArgs = {
  data: PasswordUpdateInput;
};


export type MutationUpdatePasswordAnonymousArgs = {
  data: PasswordAnonymousUpdateInput;
};


export type MutationResetPasswordArgs = {
  data: PasswordResetInput;
};


export type MutationCreateOneRegionArgs = {
  data?: Maybe<RegionCreateInput>;
};


export type MutationUpdateOneRegionArgs = {
  id: Scalars['String'];
  data?: Maybe<RegionUpdateInput>;
};


export type MutationDeleteOneRegionArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneScanArgs = {
  data?: Maybe<ScanCreateInput>;
};


export type MutationUpdateOneUserArgs = {
  id: Scalars['String'];
  data?: Maybe<UserUpdateInput>;
};


export type MutationDeleteOneUserArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneWineArgs = {
  data?: Maybe<WineCreateInput>;
};


export type MutationUpdateOneWineArgs = {
  id: Scalars['String'];
  data?: Maybe<WineUpdateInput>;
};


export type MutationDeleteOneWineArgs = {
  id: Scalars['String'];
};

export type Paging = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type PasswordAnonymousUpdateInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type PasswordLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type PasswordRegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type PasswordResetInput = {
  email: Scalars['String'];
};

export type PasswordUpdateInput = {
  id: Scalars['String'];
  password: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type Profile = {
  __typename?: 'Profile';
  user: User;
};

export type Query = {
  __typename?: 'Query';
  adviceRequests: Array<AdviceRequest>;
  adviceRequestsCount?: Maybe<Scalars['Int']>;
  adviceRequest: AdviceRequest;
  aocs: Array<Aoc>;
  aocsCount?: Maybe<Scalars['Int']>;
  aoc: Aoc;
  classifications: Array<Classification>;
  classificationsCount?: Maybe<Scalars['Int']>;
  classification: Classification;
  countries: Array<Country>;
  countriesCount?: Maybe<Scalars['Int']>;
  country: Country;
  domains: Array<Domain>;
  domainsCount?: Maybe<Scalars['Int']>;
  domain: Domain;
  firmwares: Array<Firmware>;
  firmwaresCount?: Maybe<Scalars['Int']>;
  firmware: Firmware;
  grapes: Array<Grape>;
  grapesCount?: Maybe<Scalars['Int']>;
  grape: Grape;
  profile: Profile;
  regions: Array<Region>;
  regionsCount?: Maybe<Scalars['Int']>;
  region: Region;
  scans: Array<Scan>;
  scansCount?: Maybe<Scalars['Int']>;
  scan: Scan;
  temperature: Temperature;
  users: Array<User>;
  usersCount?: Maybe<Scalars['Int']>;
  user: User;
  wines: Array<Wine>;
  winesCount?: Maybe<Scalars['Int']>;
  wine: Wine;
};


export type QueryAdviceRequestsArgs = {
  filter?: Maybe<AdviceRequestFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryAdviceRequestsCountArgs = {
  filter?: Maybe<AdviceRequestFilter>;
};


export type QueryAdviceRequestArgs = {
  id: Scalars['String'];
};


export type QueryAocsArgs = {
  filter?: Maybe<AocFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryAocsCountArgs = {
  filter?: Maybe<AocFilter>;
};


export type QueryAocArgs = {
  id: Scalars['String'];
};


export type QueryClassificationsArgs = {
  filter?: Maybe<ClassificationFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryClassificationsCountArgs = {
  filter?: Maybe<ClassificationFilter>;
};


export type QueryClassificationArgs = {
  id: Scalars['String'];
};


export type QueryCountriesArgs = {
  filter?: Maybe<CountryFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryCountriesCountArgs = {
  filter?: Maybe<CountryFilter>;
};


export type QueryCountryArgs = {
  id: Scalars['String'];
};


export type QueryDomainsArgs = {
  filter?: Maybe<DomainFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryDomainsCountArgs = {
  filter?: Maybe<DomainFilter>;
};


export type QueryDomainArgs = {
  id: Scalars['String'];
};


export type QueryFirmwaresArgs = {
  filter?: Maybe<FirmwareFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryFirmwaresCountArgs = {
  filter?: Maybe<FirmwareFilter>;
};


export type QueryFirmwareArgs = {
  id: Scalars['String'];
};


export type QueryGrapesArgs = {
  filter?: Maybe<GrapeFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryGrapesCountArgs = {
  filter?: Maybe<GrapeFilter>;
};


export type QueryGrapeArgs = {
  id: Scalars['String'];
};


export type QueryRegionsArgs = {
  filter?: Maybe<RegionFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryRegionsCountArgs = {
  filter?: Maybe<RegionFilter>;
};


export type QueryRegionArgs = {
  id: Scalars['String'];
};


export type QueryScansArgs = {
  filter?: Maybe<ScanFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryScansCountArgs = {
  filter?: Maybe<ScanFilter>;
};


export type QueryScanArgs = {
  id: Scalars['String'];
};


export type QueryTemperatureArgs = {
  filter: TemperatureFilter;
};


export type QueryUsersArgs = {
  filter?: Maybe<UserFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryUsersCountArgs = {
  filter?: Maybe<UserFilter>;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryWinesArgs = {
  filter?: Maybe<WineFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryWinesCountArgs = {
  filter?: Maybe<WineFilter>;
};


export type QueryWineArgs = {
  id: Scalars['String'];
};

export type Region = {
  __typename?: 'Region';
  id: Scalars['String'];
  name: Scalars['String'];
  country: Country;
  temperatures?: Maybe<Array<TypeTemperature>>;
};

export type RegionCreateInput = {
  name: Scalars['String'];
  country: Scalars['String'];
  temperatures?: Maybe<Array<Maybe<TypeTemperatureCreateInput>>>;
};

export type RegionFilter = {
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  country?: Maybe<CountryFilter>;
  AND?: Maybe<Array<RegionFilter>>;
  OR?: Maybe<Array<RegionFilter>>;
};

export type RegionUpdateInput = {
  name?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  temperatures?: Maybe<Array<Maybe<TypeTemperatureCreateInput>>>;
};

export enum Role {
  Admin = 'admin'
}

export type Scan = {
  __typename?: 'Scan';
  id: Scalars['String'];
  image: Scalars['String'];
  wineType: WineType;
  advices?: Maybe<Array<Maybe<ScanAdvice>>>;
  used: Scalars['Boolean'];
  owner: User;
};

export type ScanAdvice = {
  __typename?: 'ScanAdvice';
  year?: Maybe<Scalars['Int']>;
  description?: Maybe<WineDescription>;
  idealTemp: Scalars['Int'];
};

export type ScanCreateInput = {
  base64Image: Scalars['String'];
  wineType: WineType;
};

export type ScanFilter = {
  id?: Maybe<StringFilter>;
  wineType?: Maybe<WineTypeFilter>;
  used?: Maybe<BooleanFilter>;
  owner?: Maybe<UserFilter>;
  AND?: Maybe<Array<ScanFilter>>;
  OR?: Maybe<Array<ScanFilter>>;
};

export type SocialLoginInput = {
  socialType: SocialType;
  accessToken: Scalars['String'];
};

export enum SocialType {
  Facebook = 'Facebook',
  Google = 'Google',
  Apple = 'Apple'
}

export type Sort = {
  field: Scalars['String'];
  order: SortOrder;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringArrayFilter = {
  containsSome?: Maybe<Array<Maybe<Scalars['String']>>>;
  containsAll?: Maybe<Array<Maybe<Scalars['String']>>>;
  equals?: Maybe<Array<Maybe<Scalars['String']>>>;
  someContains?: Maybe<Scalars['String']>;
  allContains?: Maybe<Scalars['String']>;
  someStartsWith?: Maybe<Scalars['String']>;
  allStartsWith?: Maybe<Scalars['String']>;
  someEndsWith?: Maybe<Scalars['String']>;
  allEndsWith?: Maybe<Scalars['String']>;
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  not?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type Success = {
  __typename?: 'Success';
  success: Scalars['Boolean'];
};

export type Temperature = {
  __typename?: 'Temperature';
  value: Scalars['Int'];
};

export type TemperatureFilter = {
  type: WineType;
  relation?: Maybe<TemperatureRelationFilter>;
};

export type TemperatureRelationFilter = {
  relation: TemperatureRelationType;
  relationId: Scalars['String'];
};

export enum TemperatureRelationType {
  Grape = 'Grape',
  Aoc = 'Aoc',
  Country = 'Country',
  Region = 'Region'
}

export enum TemperatureUnit {
  F = 'F',
  C = 'C'
}


export type TypeTemperature = {
  __typename?: 'TypeTemperature';
  type: WineType;
  temperature: Scalars['Int'];
};

export type TypeTemperatureCreateInput = {
  type: WineType;
  temperature: Scalars['Int'];
};


export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  email: Scalars['String'];
  language?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  business?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  tempUnit?: Maybe<TemperatureUnit>;
  agreedToTermsAndConditions: Scalars['Boolean'];
  /** This can only be set by the admin */
  roles?: Maybe<Array<Role>>;
};

export type UserFilter = {
  id?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  language?: Maybe<StringFilter>;
  givenName?: Maybe<StringFilter>;
  familyName?: Maybe<StringFilter>;
  company?: Maybe<StringFilter>;
  business?: Maybe<StringFilter>;
  country?: Maybe<StringFilter>;
  AND?: Maybe<Array<UserFilter>>;
  OR?: Maybe<Array<UserFilter>>;
};

export type UserUpdateInput = {
  email?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  business?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  tempUnit?: Maybe<TemperatureUnit>;
  agreedToTermsAndConditions?: Maybe<Scalars['Boolean']>;
  roles?: Maybe<Array<Role>>;
};

export type Wine = {
  __typename?: 'Wine';
  id: Scalars['String'];
  type: WineType;
  description: WineDescription;
  year?: Maybe<Scalars['Int']>;
  idealTemp?: Maybe<Scalars['Int']>;
  customTemp?: Maybe<Scalars['Int']>;
  archived: Scalars['Boolean'];
  amount: Scalars['Int'];
  image: Scalars['String'];
  rating?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  owner: User;
  scan: Scan;
};

export type WineCreateInput = {
  type: WineType;
  description: WineDescriptionInput;
  year?: Maybe<Scalars['Int']>;
  idealTemp?: Maybe<Scalars['Int']>;
  customTemp?: Maybe<Scalars['Int']>;
  archived: Scalars['Boolean'];
  amount: Scalars['Int'];
  rating?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  scan: Scalars['String'];
};

export type WineDescription = {
  __typename?: 'WineDescription';
  grapeText?: Maybe<Scalars['String']>;
  grape?: Maybe<Grape>;
  aocText?: Maybe<Scalars['String']>;
  aoc?: Maybe<Aoc>;
  regionText?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  countryText?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  domainText?: Maybe<Scalars['String']>;
  domain?: Maybe<Domain>;
  classificationText?: Maybe<Scalars['String']>;
  classification?: Maybe<Classification>;
};

export type WineDescriptionFilter = {
  grapeText?: Maybe<StringFilter>;
  grape?: Maybe<GrapeFilter>;
  aocText?: Maybe<StringFilter>;
  aoc?: Maybe<AocFilter>;
  regionText?: Maybe<StringFilter>;
  region?: Maybe<RegionFilter>;
  countryText?: Maybe<StringFilter>;
  country?: Maybe<CountryFilter>;
  domainText?: Maybe<StringFilter>;
  domain?: Maybe<DomainFilter>;
  classificationText?: Maybe<StringFilter>;
  classification?: Maybe<ClassificationFilter>;
  AND?: Maybe<Array<WineDescriptionFilter>>;
  OR?: Maybe<Array<WineDescriptionFilter>>;
};

export type WineDescriptionInput = {
  grapeText?: Maybe<Scalars['String']>;
  grape?: Maybe<Scalars['String']>;
  aocText?: Maybe<Scalars['String']>;
  aoc?: Maybe<Scalars['String']>;
  regionText?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  countryText?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  domainText?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  classificationText?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
};

export type WineFilter = {
  id?: Maybe<StringFilter>;
  type?: Maybe<WineTypeFilter>;
  description?: Maybe<WineDescriptionFilter>;
  year?: Maybe<IntFilter>;
  idealTemp?: Maybe<IntFilter>;
  customTemp?: Maybe<IntFilter>;
  archived?: Maybe<BooleanFilter>;
  amount?: Maybe<IntFilter>;
  rating?: Maybe<IntFilter>;
  owner?: Maybe<UserFilter>;
  AND?: Maybe<Array<WineFilter>>;
  OR?: Maybe<Array<WineFilter>>;
};

export enum WineType {
  White = 'white',
  Red = 'red',
  Pink = 'pink',
  Sparkling = 'sparkling'
}

export type WineTypeFilter = {
  equals?: Maybe<WineType>;
  not?: Maybe<WineType>;
  in?: Maybe<Array<Maybe<WineType>>>;
  notIn?: Maybe<Array<Maybe<WineType>>>;
};

export type WineUpdateInput = {
  type?: Maybe<WineType>;
  description?: Maybe<WineDescriptionInput>;
  year?: Maybe<Scalars['Int']>;
  idealTemp?: Maybe<Scalars['Int']>;
  customTemp?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
};

export type UpdatePasswordAnonymousMutationVariables = Exact<{
  data: PasswordAnonymousUpdateInput;
}>;


export type UpdatePasswordAnonymousMutation = (
  { __typename?: 'Mutation' }
  & { updatePasswordAnonymous: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);


export const UpdatePasswordAnonymousDocument = gql`
    mutation updatePasswordAnonymous($data: PasswordAnonymousUpdateInput!) {
  updatePasswordAnonymous(data: $data) {
    success
  }
}
    `;
export type UpdatePasswordAnonymousMutationFn = Apollo.MutationFunction<UpdatePasswordAnonymousMutation, UpdatePasswordAnonymousMutationVariables>;

/**
 * __useUpdatePasswordAnonymousMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordAnonymousMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordAnonymousMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordAnonymousMutation, { data, loading, error }] = useUpdatePasswordAnonymousMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePasswordAnonymousMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordAnonymousMutation, UpdatePasswordAnonymousMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordAnonymousMutation, UpdatePasswordAnonymousMutationVariables>(UpdatePasswordAnonymousDocument, options);
      }
export type UpdatePasswordAnonymousMutationHookResult = ReturnType<typeof useUpdatePasswordAnonymousMutation>;
export type UpdatePasswordAnonymousMutationResult = Apollo.MutationResult<UpdatePasswordAnonymousMutation>;
export type UpdatePasswordAnonymousMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordAnonymousMutation, UpdatePasswordAnonymousMutationVariables>;