import { ApolloProvider } from "@apollo/client";
import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { client } from "./graphql/client";
import { ResetPasswordPage } from "./pages/resetPasswordPage";

function App() {
    return (
        <ApolloProvider client={client}>
            <BrowserRouter>
                <Switch>
                    <Route path="/:token?">
                        <ResetPasswordPage />
                    </Route>
                </Switch>
            </BrowserRouter>
        </ApolloProvider>
    );
}

export default App;
